// @ts-nocheck

import * as Sentry from "@sentry/react";
import mainStore from "../MainStore/store";
import { we_login, we_set_user_attributes, we_track } from "../commonUtils";
import { authAxios } from "../services/api";
import oauthAsyncListener from "./broadcast-polyfill";
import { localStorageSafe } from "./localStorageUtils";
import { tryCatchSafe } from "./tryCatchUtils";
import { trackAdSignup, tiktokIdentify } from "../adsTracking";

export function downloadFile(urlToSend: string, return_func: Function) {
  authAxios
    .get(urlToSend, { responseType: "blob" })
    .then((resp) => {
      const blob = resp.data;
      let fileName = resp.headers["content-disposition"];
      fileName = fileName?.trim().split("=")[1];
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      return_func && return_func(true);
    })
    .catch((er) => {
      return_func && return_func(false);
    });
}

export function B64OrUrlToFileArr(b64OrUrl, filename, mimeType) {
  if (b64OrUrl.startsWith("data:")) {
    let arr = b64OrUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const file = new File([u8arr], filename, { type: mime || mimeType });
    return Promise.resolve(file);
  }
  return fetch(b64OrUrl)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
}

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

export const GET_PERCENTAGE = (numb: number, total: number) => {
  return (numb / total) * 100;
};
export const GET_ACTUAL_OF_PERCENTAGE = (numb: number, total: number) => {
  return (numb / 100) * total;
};

export const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};
export const REFERRAL_SOURCE_LIST = shuffleArray([
  {
    label: "Social Media Influencer",
    value: "social_media_influencer"
  },
  {
    label: "Online Ads",
    value: "online_ads"
  },
  {
    label: "Word of Mouth",
    value: "word_of_mouth"
  },
  {
    label: "Google Search",
    value: "google_search"
  }
  // {
  //   label: "Product Hunt",
  //   value: "product_hunt",
  // },
]);
const setEcomData = (ecom_platform) => {
  const ecom_user = ecom_platform ? true : false;
  const platform = ecom_platform;
  mainStore.dispatch({
    type: "userData/setEcomUser",
    payload: ecom_user
  });
  mainStore.dispatch({
    type: "userData/setEcomPlatform",
    payload: platform
  });
  localStorageSafe("setItem", "ecom_user", ecom_user);
  localStorageSafe("setItem", "ecom_platform", platform);
};
export function store_user_details(
  username,
  useremail,
  user_img_url,
  msg_data,
  login_platform = undefined
) {
  mainStore.dispatch({ type: "setUserName", payload: username });
  mainStore.dispatch({ type: "setUsername", payload: useremail });
  if (user_img_url?.length > 0) {
    try {
      localStorage.setItem("user_img_url", user_img_url);
    } catch (error) {
      console.error(error.message);
    }

    mainStore.dispatch({ type: "setUserImageUrl", payload: user_img_url });
  } else {
    try {
      localStorage.setItem("user_img_url", "../assets/img/others/user.svg");
    } catch (error) {
      console.error(error.message);
    }
    mainStore.dispatch({
      type: "setUserImageUrl",
      payload: "../assets/img/others/user.svg"
    });
  }
  msg_data["onboarding_version"] &&
    mainStore.dispatch({
      type: "onboarding/setType",
      payload: msg_data["onboarding_version"]
    });

  mainStore.dispatch({
    type: "userData/setIsAdminUser",
    payload: msg_data["is_admin_user"]
  });
  mainStore.dispatch({
    type: "userData/setTemplateCreatorPermission",
    payload: msg_data["template_creator_permission"]
  });
  mainStore.dispatch({ type: "updateJobId", payload: msg_data["job_id"] });
  mainStore.dispatch({ type: "setJobType", payload: msg_data["job_type"] });
  mainStore.dispatch({
    type: "setPlatformsLinked",
    payload: JSON.stringify(msg_data["analysis_completed_for"])
  });
  mainStore.dispatch({
    type: "setJWT",
    payload: msg_data.jwt_token
  });
  setEcomData(msg_data?.user_ecom_platform);
  try {
    if (msg_data["refresh_jwt_token"]) {
      localStorage.setItem("refresh_token", msg_data["refresh_jwt_token"]);
      mainStore.dispatch({
        type: "setRefreshToken",
        payload: msg_data["refresh_jwt_token"]
      });
    }
  } catch (error) {
    console.error(error.message);
  }
  mainStore.dispatch({
    type: "userData/setLoginPlatform",
    payload: login_platform
  });
  mainStore.dispatch({ type: "setFrillSso", payload: msg_data["frillSSO"] });
  mainStore.dispatch({ type: "setIsUserIndian", payload: msg_data["user_timezone"] });
  mainStore.dispatch({ type: "setUserHasPosts", payload: msg_data["user_has_posts"] });
  msg_data["user_domain"] &&
    mainStore.dispatch({ type: "setUserDomain", payload: msg_data["user_domain"] });
  mainStore.dispatch({ type: "setChecklistEligible", payload: msg_data["checklist_eligible"] });
  mainStore.dispatch({ type: "setAttributionSource", payload: msg_data?.["attribution_source"] });
  // setting in localStorage for session persistence and backward comp
  try {
    localStorage.setItem("job_id", msg_data["job_id"]);
    localStorage.setItem("username", username);
    localStorage.setItem("isAdminUser", msg_data["is_admin_user"]);
    localStorage.setItem("templateCreatorPermission", msg_data["template_creator_permission"]);
    localStorage.setItem("useremail", useremail);
    localStorage.setItem("tour_2_end", "true");
    localStorage.setItem("job_type", msg_data["job_type"]);
    localStorage.setItem("platforms_linked", JSON.stringify(msg_data["analysis_completed_for"]));
    localStorage.setItem("login_platform", login_platform);
    localStorage.setItem("frillSSO", msg_data["frillSSO"]);
    localStorage.setItem("fbse_token", msg_data["fbse_token"]);
    localStorage.setItem("fbse_uid", msg_data["fbse_uid"]);
    localStorage.setItem("ws_status", msg_data["workspace_status"]);
    localStorage.setItem("need_tour", !msg_data["user_has_posts"]);
    localStorage.setItem("edit_tour", !msg_data["user_has_posts"]);
    localStorage.setItem("comp_tour", !msg_data["user_has_posts"]);
    localStorage.setItem("user_has_posts", msg_data["user_has_posts"]);
    localStorage.setItem("jwt", msg_data["jwt_token"]);
    localStorage.setItem("onboarding_version", msg_data["onboarding_version"]);
    msg_data["user_domain"] && localStorage.setItem("user_domain", msg_data["user_domain"]);
    localStorage.setItem("checklist_eligible", msg_data["checklist_eligible"]);
    localStorage.setItem("attribution_source", msg_data?.["attribution_source"]);
  } catch (error) {
    console.log("no localstorage access");
  }

  // we track
  const { isWebView } = mainStore.getState().uiStates;

  tryCatchSafe(() => tiktokIdentify(msg_data.fbse_uid), "error in tiktokIdentify");
  tryCatchSafe(() => we_login(msg_data.fbse_uid), "error in we_login");
  tryCatchSafe(
    () =>
      we_set_user_attributes({
        we_email: useremail,
        login_source: login_platform,
        platform: isWebView ? "app" : "web",
        activated: msg_data["user_has_posts"],
        onboarding_version: msg_data["onboarding_version"],
        attribution_source: msg_data?.["attribution_source"]
      }),
    "error in we_set_user_attributes"
  );

  // sentry set attribute
  Sentry.setUser({ email: useremail });
  tryCatchSafe(
    () =>
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: "userData",
          data: {
            useremail: useremail,
            userid: msg_data.fbse_uid
          }
        })
      ),
    "error in userData postMessage"
  );
  if (msg_data?.is_signup) {
    localStorageSafe("setItem", "is_signup", "true");
    tryCatchSafe(() => we_track("user_signup"), "error in we_track signup");

    //For ad related tracking
    trackAdSignup(useremail);
  }
}

export const isBrave = async () => {
  return (navigator.brave && (await navigator.brave.isBrave())) || false;
};

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=.predis.ai;path=/";
}

export function getCookie(cname) {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function endsWithAny(suffixes, string) {
  return suffixes?.some(function (suffix) {
    return string?.endsWith(suffix);
  });
}

export const GET_VIDEO_SIZE = (videoFile) => {
  return new Promise((resolve, reject) => {
    const isFile = (input) => "File" in window && input instanceof File;
    if (!isFile(videoFile)) {
      reject("Please Provide a Video File.");
      return;
    }
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.addEventListener("loadedmetadata", (event) => {
      resolve({ width: video.videoWidth, height: video.videoHeight, duration: video.duration });
      // console.log(video.videoWidth, video.videoHeight);
    });
  });
};

export const openExternalLink = (e, url) => {
  const { isWebView } = mainStore.getState().uiStates;
  if (isWebView) {
    e.preventDefault();
    window?.ReactNativeWebView?.postMessage(`redirect||${url}`);
  }
};

export const getListenerParent = () => {
  return getMobileOperatingSystem() === "Android" ? document : window;
};

export const openLink = async (url, oauth_popup, use_opener) => {
  const { isWebView } = mainStore.getState().uiStates;

  return new Promise((resolve, reject) => {
    if (isWebView) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: "redirectresponse", url: url })
      );

      const listner = (e) => {
        const { data } = e;
        try {
          const [type, jsonData] = data.split("||");
          if (type === "response") {
            resolve(JSON.parse(jsonData)); // Resolve with jsonData
          }
        } catch (error) {
          reject(error.message);
        } finally {
          getListenerParent().removeEventListener("message", listner);
        }
      };

      getListenerParent().addEventListener("message", listner);
    } else {
      return oauthAsyncListener(use_opener, {
        oauth_popup: oauth_popup,
        oauth_url: url
      })
        .then((_) => resolve(_))
        .catch((_) => reject(_));

      // if (signal && signal?.length > 0) {
      //   const bc = new BroadcastChannel("predis_channel");
      //   bc.onmessage = (event) => {
      //     if (event?.data?.source === signal) {
      //       resolve(event.data?.data);
      //       bc.close();
      //     }
      //   };
      // } else {
      //   resolve(true);
      // }
    }
  });
};

export const openResponseLink = (url, signal, new_window) => {
  const { isWebView } = mainStore.getState().uiStates;

  return new Promise((resolve, reject) => {
    if (isWebView) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ type: "redirectresponse", url: url })
      );

      const listner = (e) => {
        const { data } = e;
        try {
          const [type, jsonData] = data.split("||");
          const parseData = JSON.parse(jsonData);
          const { source, ...sendData } = parseData;
          if (type === "response" && source === signal) {
            resolve(sendData); // Resolve with jsonData
          }
        } catch (error) {
          reject(error.message);
        } finally {
          getListenerParent().removeEventListener("message", listner);
        }
      };
      getListenerParent().addEventListener("message", listner);
    } else {
      // change window location
      if (new_window) new_window.location = url;
      if (signal && signal?.length > 0) {
        const bc = new BroadcastChannel("predis_channel");
        bc.onmessage = (event) => {
          if (event?.data?.source === signal) {
            resolve(event.data?.data);
            bc.close();
          }
        };
      } else {
        resolve(true);
      }
    }
  });
};

export const keyMirror: any = (...list) => {
  let newList = {};
  list.map((element) => {
    var key = String(element);
    newList[key] = element;
  });
  return newList;
};
